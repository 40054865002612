import * as React from "react";
import { getAuth } from "firebase/auth";
import { navigate } from "gatsby";
import { DEBUG_MODE } from "./src/globals/Constants";
import { Database } from "./src/helpers/DataAPI";
import { Provider } from "react-redux";
import store from "./src/state/store";

export const wrapRootElement = function ({ element }) {
  return <Provider store={store}>{element}</Provider>;
};

export function wrapPageElement({ element, props }) {
  Database.Repository.attemptInitialiseApp();

  const auth = getAuth();
  auth.onAuthStateChanged(function (user) {
    // Redirect to the login page if the user is not logged in
    if (!DEBUG_MODE && !user) {
      navigate("/");
      return;
    }
  });

  return <div {...props}>{element}</div>;
}
