import { configureStore } from "@reduxjs/toolkit";
import { useSelector, TypedUseSelectorHook } from "react-redux";
import { authReducer } from "./authSlice";
import { customerReducer } from "./customerSlice";
import { ordersReducer } from "./ordersSlice";
import { productReducer } from "./productSlice";
import { templateReducer } from "./templateSlice";

const store = configureStore({
  reducer: {
    orders: ordersReducer.reducer,
    templates: templateReducer.reducer,
    customers: customerReducer.reducer,
    products: productReducer.reducer,
    auth: authReducer.reducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

export default store;
export const useAppSelector: TypedUseSelectorHook<
  ReturnType<typeof store.getState>
> = useSelector;
