import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { BakeryOrderV2 } from "@dessertsapp/models/OrdersV2";

interface State {
  orders: BakeryOrderV2[];
}

export const ordersReducer = createSlice({
  name: "orders",
  initialState: { orders: [] } as State,
  reducers: {
    set(state, action: PayloadAction<BakeryOrderV2[]>) {
      state.orders = action.payload;
    },
    add(state, action: PayloadAction<BakeryOrderV2>) {
      state.orders.push(action.payload);
    },

    updateByValue(state, action: PayloadAction<BakeryOrderV2>) {
      // Find the index of the order by id
      const orderIndex = state.orders.findIndex(
        (order) => order.id == action.payload.id
      );

      if (orderIndex === -1) {
        throw new Error(
          `Failed to find order ${JSON.stringify(action.payload)}`
        );
      }
      state.orders[orderIndex] = action.payload;
    },

    updateByIndex(
      state,
      action: PayloadAction<{ index: number; order: BakeryOrderV2 }>
    ) {
      const { index, order } = action.payload;
      if (!state.orders[index]) {
        throw new Error(`Failed to find order at index '${index}'`);
      }
      state.orders[index] = order;
    },
  },
});
