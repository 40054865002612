import { Product } from "@dessertsapp/models/GoodsV2";
import { Customer } from "@dessertsapp/models/CustomerV2";
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

interface State {
  products: Product[];
}

export const productReducer = createSlice({
  name: "products",
  initialState: { products: [] as Product[] } as State,
  reducers: {
    set(state, action: PayloadAction<Product[]>) {
      state.products = action.payload;
    },
    add(state, action: PayloadAction<Product>) {
      state.products.push(action.payload);
    },

    updateByValue(state, action: PayloadAction<Product>) {
      // Find the index of the order by id
      const index = state.products.findIndex(
        (customer) => customer.id == action.payload.id
      );

      if (index === -1) {
        throw new Error(
          `Failed to find product ${JSON.stringify(action.payload)}`
        );
      }
      state.products[index] = action.payload;
    },

    updateByIndex(
      state,
      action: PayloadAction<{ index: number; customer: Product }>
    ) {
      const { index, customer } = action.payload;
      if (!state.products[index]) {
        throw new Error(`Failed to find product at index '${index}'`);
      }
      state.products[index] = customer;
    },
  },
});
