import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { BakeryOrderV2 } from "@dessertsapp/models/OrdersV2";
import { Template } from "@dessertsapp/models/Template";

interface State {
  templates: Template[];
}

export const templateReducer = createSlice({
  name: "templates",
  initialState: { templates: [] } as State,
  reducers: {
    set(state, action: PayloadAction<Template[]>) {
      state.templates = action.payload;
    },
    add(state, action: PayloadAction<Template>) {
      state.templates.push(action.payload);
    },

    delete(state, action: PayloadAction<{ templateId: string }>) {
      state.templates = state.templates.filter(
        (t) => action.payload.templateId !== t.id
      );
    },

    updateByValue(state, action: PayloadAction<Template>) {
      // Find the index of the order by id
      const templateIndex = state.templates.findIndex(
        (order) => order.id == action.payload.id
      );

      if (templateIndex === -1) {
        throw new Error(
          `Failed to find template ${JSON.stringify(action.payload)}`
        );
      }
      state.templates[templateIndex] = action.payload;
    },

    updateByIndex(
      state,
      action: PayloadAction<{ index: number; order: BakeryOrderV2 }>
    ) {
      const { index, order } = action.payload;
      if (!state.templates[index]) {
        throw new Error(`Failed to find template at index '${index}'`);
      }
      state.templates[index] = order;
    },
  },
});
