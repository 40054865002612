import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

interface State {
  isAuthenticated: boolean;
}

export const authReducer = createSlice({
  name: "auth",
  initialState: { isAuthenticated: false } as State,
  reducers: {
    set(state, action: PayloadAction<boolean>) {
      state.isAuthenticated = action.payload;
    },
  },
});
