import { Customer } from "@dessertsapp/models/CustomerV2";
import { createSlice, type PayloadAction } from "@reduxjs/toolkit";

interface State {
  customers: Customer[];
}

export const customerReducer = createSlice({
  name: "customers",
  initialState: { customers: [] } as State,
  reducers: {
    set(state, action: PayloadAction<Customer[]>) {
      state.customers = action.payload;
    },
    add(state, action: PayloadAction<Customer>) {
      state.customers.push(action.payload);
    },

    updateByValue(state, action: PayloadAction<Customer>) {
      // Find the index of the order by id
      const index = state.customers.findIndex(
        (customer) => customer.id == action.payload.id
      );

      if (index === -1) {
        throw new Error(
          `Failed to find customer ${JSON.stringify(action.payload)}`
        );
      }
      state.customers[index] = action.payload;
    },

    updateByIndex(
      state,
      action: PayloadAction<{ index: number; customer: Customer }>
    ) {
      const { index, customer } = action.payload;
      if (!state.customers[index]) {
        throw new Error(`Failed to find customer at index '${index}'`);
      }
      state.customers[index] = customer;
    },
  },
});
