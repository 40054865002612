import { Bakery } from "@dessertsapp/models/Bakery";
import { Customer } from "@dessertsapp/models/CustomerV2";
import { Product } from "@dessertsapp/models/GoodsV2";
import { CompiledOrder } from "@dessertsapp/models/Orders";
import { BakeryOrderV2 } from "@dessertsapp/models/OrdersV2";
import { Template } from "@dessertsapp/models/Template";
import { get, put, patch, post, del } from "aws-amplify/api";

import * as firebase from "firebase/app";
import { Database } from ".";
import { BakeryUrl } from "../../models/BakeryUrl";
import { SheetUser } from "../../models/SheetUser";
import { IRepository } from "./IRepository";

/**
 * Wraper for aws-amplify REST API methods.
 * This will wrap any amplify REST API method and return a function that accepts a path and options.
 *
 * The options argument and return type are inferred automatically or can be set
 *
 * @param base The base REST API method to use
 * @returns
 */
function amplifyWrapper<T extends typeof get>(base: T) {
  return async function wrappedAPI<R>(
    path: string,
    options?: Parameters<T>[0]["options"]
  ) {
    try {
      const response = await base({
        apiName: "main",
        path,
        options,
      }).response;

      return response.body.json() as any as R;
    } catch (error) {
      // If the error is thrown by Amplify it will be a `RestApiError` instance
      // The class is not exported by the library so we need to check the error properties
      // https://docs.amplify.aws/react/build-a-backend/restapi/fetch-data/#pageMain
      if (error["$metadata"] && error["$metadata"].httpStatusCode) {
        throw new Error(
          `Failed with status code ${error["$metadata"].httpStatusCode}`
        );
      }
      throw error;
    }
  };
}

const _get = amplifyWrapper(get);
const _patch = amplifyWrapper(patch);
const _post = amplifyWrapper(post);
const _put = amplifyWrapper(put);

export class DatabaseAWS implements IRepository {
  attemptInitialiseApp() {
    firebase.initializeApp(Database.firebaseConfig);
  }

  /**
   * Retrieve all products in the database
   * @returns Promise with database values
   */
  async getAllGoods(): Promise<Product[]> {
    return _get("/db/getAllGoods", {
      queryParams: {
        category: "any",
      },
    });
  }

  /**
   * Retrieve a product from the database
   * @returns Promise with database values
   */
  async getGoodById(id: string): Promise<Product> {
    return _get("/db/getGoodById", {
      queryParams: {
        id,
      },
    });
  }

  /**
   * Retrieve all bakeries in the data
   * @returns Promise with database values
   */
  async getAllBakeries(): Promise<{ [id: string]: Bakery }> {
    throw new Error("DatabaseAWS getAllBakeries not implemented");
  }

  /**
   * Request a bakery from the database by a string id.
   * @param id A bakery Id
   * @returns Promise of a singular bakery from the database. Resolves to null if no bakery is found
   */
  async getBakeryById(id: string): Promise<Bakery> {
    throw new Error("DatabaseAWS getBakeryById not implemented");
  }

  /**
   * Retrieve all sheet users in the data
   * @returns Promise with database values
   */
  async getAllSheetUsers(): Promise<{ [id: string]: SheetUser }> {
    throw new Error("DatabaseAWS getAllSheetUsers not implemented");
  }

  /**
   * Request a sheet user from the database by a string id.
   * @param id A user Id
   * @returns Promise of a singular bakery from the database. Resolves to null if no user is found
   * @deprecated
   */
  async getSheetUserById(id: string): Promise<SheetUser> {
    throw new Error("DatabaseAWS getSheetUserById not implemented");
  }

  /**
   * Request a sheet url id from the database by a string id.
   * @param id A bakery Id
   * @returns Promise of a singular bakery url from the database. Resolves to null if no bakery is found
   * @deprecated
   */
  async getBakeryUrlById(id: string): Promise<BakeryUrl> {
    throw new Error("DatabaseAWS getBakeryUrlById not implemented");
  }

  /**
   * Save a new order request to the database. This is used for adding new baked goods to the database in a 'pending' state.
   * @deprecated
   * @param data Any data to be saved in the database
   * @returns
   */
  async saveGoodRequest(data: CompiledOrder): Promise<CompiledOrder> {
    throw new Error("DatabaseAWS saveGoodRequest not implemented");
  }

  async getOrders(bakeryId: string): Promise<BakeryOrderV2[]> {
    return _get("/order", {
      queryParams: {
        bakeryId,
      },
    });
  }

  async updateOrder(order: BakeryOrderV2): Promise<BakeryOrderV2> {
    return _patch("/order", {
      body: order as any,
    });
  }

  async getTemplates(bakeryId: string): Promise<Template[]> {
    return _get("/template", {
      queryParams: {
        bakeryId,
      },
    });
  }

  async updateTemplate(template: Template): Promise<Template> {
    return _patch("/template", {
      body: template as any,
    });
  }

  async deleteTemplate(
    bakeryId: string,
    templateId: string
  ): Promise<{ templateId: string }> {
    return _get("/template/delete", {
      queryParams: {
        bakeryId,
        templateId,
      },
    });
  }

  async createTemplate(
    template: Pick<Template, "bakeryId" | "name" | "template">
  ): Promise<Template> {
    return _post("/template", {
      body: template as any,
    });
  }

  async getCustomers(bakeryId: string): Promise<Customer[]> {
    return _get("/customer");
  }

  async updateCustomer(customer: Customer): Promise<Customer> {
    return _patch("/customer", {
      body: customer as any,
    });
  }
}
