import { Bakery } from "@dessertsapp/models/Bakery";
import { Customer } from "@dessertsapp/models/CustomerV2";
import { Product } from "@dessertsapp/models/GoodsV2";
import { CompiledOrder } from "@dessertsapp/models/Orders";
import { BakeryOrderV2 } from "@dessertsapp/models/OrdersV2";
import { bakeryOrderSchema } from "@dessertsapp/models/schemas/bakeryOrder.schema";
import { customerSchema } from "@dessertsapp/models/schemas/customer.schema";
import { productSchema } from "@dessertsapp/models/schemas/product.schema";
import { templateSchema } from "@dessertsapp/models/schemas/template.schema";
import { Template } from "@dessertsapp/models/Template";
import * as yup from "yup";
import { BakeryUrl } from "../../models/BakeryUrl";
import { bakeryOrderListSchema } from "../../models/schemas/bakeryOrder.schema";
import { SheetUser } from "../../models/SheetUser";
import { customerReducer } from "../../state/customerSlice";
import { ordersReducer } from "../../state/ordersSlice";
import { productReducer } from "../../state/productSlice";
import store from "../../state/store";
import { templateReducer } from "../../state/templateSlice";
import { AsyncReturnType } from "../../types";
import { IController } from "./IController";
import { IRepository } from "./IRepository";

/**
 * Primary controller for data.
 * TODO: Splitting this controller into specific groups: Orders, Users, Messages, Menu, Settings
 */
export class DataAPIController implements IController {
  private readonly reduxDispatch = store.dispatch;
  constructor(private repository: IRepository) {
    /**
     * APIs need to be recreated here so that methodBuilder has access to `this.repository`
     */

    this.getOrders = methodBuilder(this.repository.getOrders)
      .setPostRequestValidation(
        (_) => bakeryOrderListSchema.validate(_) as Promise<BakeryOrderV2[]>
      )
      .setSideEffect((data) => {
        this.reduxDispatch(ordersReducer.actions.set(data));
      })
      .build();

    this.updateOrder = methodBuilder(this.repository.updateOrder)
      .setPreRequestValidation(
        (_) => bakeryOrderSchema.validate(_) as Promise<BakeryOrderV2>
      )
      .setPostRequestValidation(
        (_) => bakeryOrderSchema.validate(_) as Promise<BakeryOrderV2>
      )
      .setSideEffect((data) => {
        this.reduxDispatch(ordersReducer.actions.updateByValue(data));
      })
      .build();

    this.getTemplates = methodBuilder(this.repository.getTemplates)
      .setPreRequestValidation((_) => yup.string().required().validate(_))
      .setPostRequestValidation((_) =>
        yup.array(templateSchema).required().validate(_)
      )
      .setSideEffect((data) => {
        this.reduxDispatch(templateReducer.actions.set(data));
      })
      .build();

    this.getCustomers = methodBuilder(this.repository.getCustomers)
      .setPostRequestValidation((_) =>
        yup.array(customerSchema.required()).validate(_)
      )
      .setSideEffect((data) => {
        this.reduxDispatch(customerReducer.actions.set(data));
      })
      .build();

    this.updateCustomer = methodBuilder(this.repository.updateCustomer)
      .setPreRequestValidation((_) => customerSchema.validate(_))
      .setPostRequestValidation((_) => customerSchema.validate(_))
      .setSideEffect((data) => {
        this.reduxDispatch(customerReducer.actions.updateByValue(data));
      })
      .build();

    this.getAllGoods = methodBuilder(this.repository.getAllGoods)
      .setPostRequestValidation(
        (_) =>
          yup.array(productSchema).required().validate(_) as Promise<Product[]>
      )
      .setSideEffect((data) => {
        this.reduxDispatch(productReducer.actions.set(data));
      })
      .build();

    this.updateTemplate = methodBuilder(this.repository.updateTemplate)
      .setPreRequestValidation((_) => templateSchema.validate(_))
      .setPostRequestValidation((_) => templateSchema.validate(_))
      .setSideEffect((data) => {
        this.reduxDispatch(templateReducer.actions.updateByValue(data));
      })
      .build();

    this.createTemplate = methodBuilder(this.repository.createTemplate)
      .setPreRequestValidation((_) => {
        return templateSchema
          .pick(["name", "template", "bakeryId"])
          .validate(_);
      })
      .setPostRequestValidation((template) => templateSchema.validate(template))
      .setSideEffect((data) => {
        this.reduxDispatch(templateReducer.actions.add(data));
      })
      .build();

    this.deleteTemplate = methodBuilder(this.repository.deleteTemplate)
      .setSideEffect((templateId: string) =>
        this.reduxDispatch(templateReducer.actions.delete(templateId))
      )
      .build();

    this.getBakeryById = methodBuilder(this.repository.getBakeryById).build();
  }
  getAllGoods: () => Promise<Product[]>;
  getGoodById: (id: string) => Promise<Product>;
  getAllBakeries: () => Promise<{ [id: string]: Bakery }>;
  getBakeryById: (id: string) => Promise<Bakery>;
  getAllSheetUsers: () => Promise<{ [id: string]: SheetUser }>;
  getSheetUserById: (id: string) => Promise<SheetUser>;
  getBakeryUrlById: (id: string) => Promise<BakeryUrl>;
  saveGoodRequest: (data: CompiledOrder) => Promise<CompiledOrder>;
  getOrders: (bakeryId: string) => Promise<BakeryOrderV2[]>;
  updateOrder: (order: BakeryOrderV2) => Promise<BakeryOrderV2>;
  getTemplates: (bakeryId: string) => Promise<Template[]>;
  updateTemplate: (template: Template) => Promise<Template>;
  createTemplate: (
    template: Pick<Template, "bakeryId" | "name" | "template">
  ) => Promise<Template>;
  deleteTemplate: (bakeryId: string, templateId: string) => Promise<any>;
  getCustomers: (bakeryId: string) => Promise<Customer[]>;
  updateCustomer: (customer: Customer) => Promise<Customer>;

  attemptInitialiseApp() {
    return this.repository.attemptInitialiseApp();
  }
}

/**
 * Helper method to build APIs that have pre and post validation without extra boilerplate
 */
export function methodBuilder<
  RepositoryCall extends (...args: any) => Promise<any>,
  Value extends AsyncReturnType<RepositoryCall>
>(action: RepositoryCall) {
  interface Options {
    preRequestValidation: (
      ...params: Parameters<RepositoryCall>
    ) => Promise<any> | any;
    postRequestValidation: (data: Value) => Promise<Value> | Value;
    action: RepositoryCall;
    sideEffect: (data: Value) => any;
  }
  const options: Options = {
    action,
    preRequestValidation: () => !!0,
    postRequestValidation: (_) => _,
    sideEffect: () => !!0,
  };
  const builderOptions = {
    setPreRequestValidation(validator: Options["preRequestValidation"]) {
      options.preRequestValidation = validator;
      return builderOptions;
    },

    setPostRequestValidation(validator: Options["postRequestValidation"]) {
      options.postRequestValidation = validator;
      return builderOptions;
    },

    setSideEffect(sideEffect: Options["sideEffect"]) {
      options.sideEffect = sideEffect;
      return builderOptions;
    },
    build() {
      return async function (...data: Parameters<RepositoryCall>) {
        // Validate the request before it is sent
        await options.preRequestValidation(...data);
        let result = undefined;
        try {
          result = await options.action(...(data as any));
        } catch (e) {
          // Append the function name to the error
          const functionName = options.action.name;
          e.message = `API: ${functionName}: ${e.message}`;

          console.log(`${e.message}`);

          // Bubble the error upwards
          throw e;
        }

        // Validate and use the transformed validator response
        result = await options.postRequestValidation(result);

        // Apply any side effects
        await options.sideEffect(result);

        // Return the value
        return result as Value;
      };
    },
  };

  return builderOptions;
}
