import { PickupTime } from "@dessertsapp/models/Bakery";
import * as firebasedb from "firebase/database";
import { DEBUG_MODE } from "../../globals/Constants";
import { DataAPIController } from "./Controller";
import { DatabaseAWS } from "./DatabaseAWS";
import { DatabaseDebug } from "./DatabaseDebug";
import { IController } from "./IController";

export namespace Database {
  /**
   * Database design:
   *
   * {
   *  bakedGoods {
   *    [id:string]: BakedGood
   *  },
   *  bakeries: {
   *    [id:string]: Bakery
   *  }
   * }
   */

  export namespace Fallbacks {
    // Sample pickup times to be used for every bakery
    export const pickupTimeFallback: PickupTime[] = [
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
    ]
      .map((day) =>
        ["1:00pm-2:00pm", "2:00pm-3:00pm", "3:00pm-4:00pm"].map((time) => ({
          day,
          time,
        }))
      )
      .flat();
  }

  /**
   * Interface used to specify the model being retrieved from a firebase data request.
   * Some elements are explicitly ommited so that they can be redefined with a generic type.
   */
  export interface GenericFirebaseDataSnapshot<T>
    extends Omit<firebasedb.DataSnapshot, "val"> {
    val: () => T;
  }

  export const firebaseConfig = {
    apiKey: "AIzaSyDUZV-qTThWp9_Zh2Y51d4LN2dyoRu_O70",
    authDomain: "desertsapp.firebaseapp.com",
    databaseURL: "https://desertsapp-default-rtdb.firebaseio.com",
    projectId: "desertsapp",
    storageBucket: "desertsapp.appspot.com",
    messagingSenderId: "515061749973",
    appId: "1:515061749973:web:ecb3e68328d74b03d86a5c",
  };

  export const Repository: IController = new DataAPIController(
    new DatabaseAWS()
  );
}
