export const DEBUG_MODE = true;
export const WEEKDAY_LIST = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
export const PROD_BAKERY = "DessertsApp";
